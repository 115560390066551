<template>
  <div class="loanapplication">
    <van-nav-bar
      :title="$t('LOANAPPLICATION')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="contentContainer">
      <div class="title">{{$t('CONSENTBORROWMESSAGE')}}</div>
      <div class="message">
         <van-cell class="borrowMoneyBox">
           <div class="borrowMoneyText font" slot="title">{{$t('BORRORWMONEY')}}</div>
           <div class="borrowMoney font" slot="default">฿{{content.money}}</div>
         </van-cell>
         <van-cell class="borrowTimeBox">
           <div class="borrowTimeText font" slot="title">{{$t('BORRORWMONEYTIME')}}</div>
           <div class="borrowTime font" slot="default">{{content.month}}{{$t('MONTHS')}}</div>
         </van-cell>
      </div>
    </div>
    <div class="protocolBox">
      <van-checkbox class="checkedStyle" icon-size="16px"  checked-color="#FC7409" shape="square" v-model="checked"></van-checkbox>
      <div class="protocol">{{$t('PLEACHECKPROTOCOL')}}</div>
      <div class="delegate float_leftAndColors">{{$t('AGREEMENTENT')}}</div>
      <!-- <div style="color:red" class="qingkanhao">{{$t('Pleasereviewtheprotocolcarefully')}}</div> -->
    </div>
    <van-button :disabled="isDisable" color="#3BCE5B" @click="sub" class="sub" type="primary" block>{{$t('SUBMITBORROWTEXT')}}</van-button>
  </div>
</template>

<script>
import { apply } from '@/api/home'
import { Toast } from 'vant'
import './css/loanapplication.css'
export default {
  name: 'loanapplication',
  data () {
    return {
      checked: true,
      isDisable: false
    }
  },
  computed: {
    content () {
      return this.$route.params
    }
  },
  created () {
    // console.log(this.$route.params)
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async sub () {
      const that = this
      if (!this.checked) {
        Toast(this.$t('CONSENTPROTOCOL'))
        return false
      }
      this.isDisable = true
      setTimeout(() => {
        this.isDisable = false
      }, 5000)
      const { data } = await apply({
        money: this.content.money,
        months: this.content.month
      })
      if (data.code === 200) {
        Toast.loading({
          message: this.$t('AWAIT'),
          forbidClick: true,
          loadingType: 'spinner',
          onClose () {
            // that.$router.push({
            //   name: 'borrowdetail',
            //   params: {
            //     id: data.data
            //   }
            // })
            that.$router.push({
              path: '/signature',
              query: {
                id: data.data
              }
            })
          }
        })
      }
      if (data.code === 500) {
        Toast.fail(data.message)
        return false
      }
      if (data.code === 1002) {
        Toast(data.message)
        setTimeout(() => {
          this.$router.push({
            path: '/my/mydata'
          })
        }, 2500)
        return false
      }
    }
  }
}
</script>

<style scoped>
.float_leftAndColors {
  font-size: 14px;
  color: #FC7409;
}
.contentContainer {
  box-sizing: border-box;
  padding: 15px;
  background: #fff;
}
.message {
  margin-top: 20px;
  border-top: 1px solid #e8e8e8;
}
.title {
  font-size: 16px;
}
.font {
  font-size: 15px;
}
.borrowMoneyText {
  font-size: 15px;
}
.protocolBox {
  margin: 20px;
  overflow: hidden;
}
.checkedStyle,
.protocol {
  float: left;
}
.protocol {
  font-size: 12px;
  color: #666;
  margin-left: 30px;
}
.sub {
  width: 350px;
  margin: 0 auto;
  font-size: 17px;
}
</style>
